/* src/App.css */
body {
  background-color: #ffffff;
  color: #333333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.course-card,
.book-card {
  background-color: #ffffff;
  color: #333333;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.course-card:hover,
.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.course-level {
  color: #007bff;
  font-weight: 600;
}

.course-title,
.book-title {
  color: #333333;
  font-weight: 600;
}

.cta-button {
  background-color: #007bff;
  color: #ffffff;
}

.cta-button:hover {
  background-color: #0056b3;
}

.section-title {
  color: #333333;
}

.section-title::after {
  background-color: #007bff;
}

main {
  margin-top: 80px; /* 헤더의 높이에 따라 조정 */
}

/* 링크 스타일 추가 */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
