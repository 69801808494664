header {
    background-color: #ffffff !important;
    padding: 15px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: #333333; /* 파란색에서 어두운 회색으로 변경 */
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    transition: color 0.3s ease;
}

.logo:hover {
    color: #007bff; /* 호버 시 파란색으로 변경 */
}

.nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin-left: 30px;
}

.nav-links a {
    color: #333333;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        margin-top: 15px;
        flex-wrap: wrap;
    }

    .nav-links li {
        margin-left: 0;
        margin-right: 20px;
        margin-bottom: 10px;
    }
}
