.courses-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Pretendard", "Apple SD Gothic Neo", sans-serif;
  color: #333;
}

.courses-content {
  margin-top: 20px;
}

section {
  margin-bottom: 80px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.youtube-section {
  display: flex;
  align-items: center;
  padding: 40px;
  gap: 40px;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.youtube-section .content {
  flex: 1;
}

.emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;
}

.large-emoji {
  font-size: 80px;
  line-height: 1;
  background: linear-gradient(135deg, #ff0000 0%, #cc0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 0, 0, 0.2);
}

.consulting-section {
  padding: 60px 40px;
  background-color: #fff;
}

.consulting-header {
  text-align: center;
  margin-bottom: 50px;
}

.consulting-description {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.consulting-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
}

.consulting-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
}

.consulting-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 50%;
  margin: 0 auto 20px;
}

.consulting-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.consulting-card .content {
  margin-top: 20px;
}

.consulting-card ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 30px;
}

.consulting-card ul li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 28px;
  font-size: 1rem;
  line-height: 1.5;
}

.consulting-card ul li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #007bff;
  font-weight: bold;
}

.image-container {
  margin-top: 40px;
  text-align: center;
}

.coaching-image {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.coaching-image:hover {
  transform: scale(1.02);
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-align: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  width: 100%;
}

.cta-button:hover {
  background: linear-gradient(135deg, #0056b3 0%, #003d80 100%);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 123, 255, 0.4);
}

@media (max-width: 992px) {
  .youtube-section {
    flex-direction: column;
    text-align: center;
  }

  .emoji-container {
    margin-top: 30px;
  }

  .consulting-cards {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .courses-container {
    padding: 20px 15px;
  }

  section {
    padding: 30px 20px;
  }

  .consulting-card {
    padding: 25px;
  }

  .card-icon {
    width: 60px;
    height: 60px;
    font-size: 2rem;
  }

  .consulting-card h3 {
    font-size: 1.3rem;
  }

  .contact-info {
    padding: 25px 15px;
  }
}

@media (max-width: 576px) {
  .consulting-cards {
    grid-template-columns: 1fr;
  }

  .consulting-card {
    max-width: 100%;
  }
}

/* 문의 안내 섹션 스타일 */
.contact-info {
  padding: 30px;
  border-radius: 16px;
  background-color: #f8f9fa;
  margin-top: 60px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.contact-info h2 {
  margin-bottom: 20px;
  color: #333;
}

.email-link {
  transition: all 0.3s ease;
}

.email-link:hover {
  color: #0056b3;
  transform: translateY(-3px);
}
