.deeplink-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.open-app-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.open-app-button:hover {
  background-color: #0056b3;
}