.footer {
  background-color: #283747;
  color: #8B959E;
  padding: 40px 0;
  margin-top: 60px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-section h3 {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 0.9rem;
  line-height: 1.5;
}

.email {
  color: #FF3F20;
  cursor: pointer;
  transition: color 0.3s ease;
}

.email:hover {
  color: #ff6347;
}

.social-links {
  display: flex;
  flex-direction: column;
}

.social-links a {
  color: #8B959E;
  text-decoration: none;
  margin-bottom: 5px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 30px;
  }
}
