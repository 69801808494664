.about-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

.about-hero {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 60px;
}

.profile-image-container {
  flex-shrink: 0;
}

.profile-image,
.profile-image-placeholder {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-image-placeholder {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #666;
}

.hero-content {
  flex-grow: 1;
}

.hero-content h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

.hero-content .subtitle {
  font-size: 0.6em;
  color: #666;
  display: block;
}

.hero-content .tagline {
  font-size: 1.4em;
  color: #007bff;
  margin-bottom: 10px;
}

.about-section {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 600px 1fr;
  gap: 20px;
  align-items: start;
}

.about-section h2 {
  font-size: 1.8em;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 20px;
  grid-column: 1 / -1;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

h1,
h2,
h3 {
  color: #333;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

section {
  margin-bottom: 40px;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.social-links li {
  background-color: #f8f9fa;
  padding: 10px 15px;
  border-radius: 5px;
}

.social-links a {
  color: #007bff;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .about-section {
    grid-template-columns: 1fr;
  }
}

.about-section h3 {
  font-size: 1.4em;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
}

.award-images {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.award-images img {
  max-width: 45%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.award-images img:hover {
  transform: scale(1.05);
}

.qualifications-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  list-style-type: none;
  padding: 0;
}

.qualifications-list li {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .qualifications-list {
    grid-template-columns: 1fr;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}
