.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero 섹션 */
.hero {
  background-image: url('../../assets/images/home_top_bg.webp');
  background-size: cover;
  background-position: center right;
  height: 500px;
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  position: relative;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero .container {
  position: relative;
  z-index: 1;
  color: #333333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  height: 100%; /* 컨테이너의 높이를 100%로 설정 */
  display: flex;
  flex-direction: column;
  justify-content: center; /* 수직 중앙 정렬 */
}

.hero-content {
  max-width: 600px;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.hero-description {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.hero-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hero-details li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333333;
}

/* 링크 스타일 초기화 */
a {
  text-decoration: none;
  color: inherit;
}

/* 기존 스타일 유지 */

/* CTA 버튼에만 특별한 스타일 적용 */
.cta-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* 책과 강좌 카드 링크 스타일 */
.book-item,
.course-item {
  text-decoration: none;
  color: inherit;
  display: block;
}

/* 섹션 공통 스타일 */
.books,
.courses {
  padding: 60px 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.section-title::after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  background-color: #007bff;
  margin: 20px auto 0;
}

/* 책 및 강좌 리스트 */
.book-list,
.course-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

/* 책 카드 */
.book-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.book-image {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 4;
  object-fit: cover;
}

.book-title {
  padding: 16px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  text-align: center;
}

/* 강좌 카드 */
.course-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.course-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.course-info {
  padding: 16px;
}

.course-level {
  font-size: 0.85rem;
  color: #007bff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.course-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
}

/* 호버 효과 */
.book-card:hover,
.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .book-list,
  .course-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero .container {
    padding: 40px 20px; /* 모바일에서 상하 패딩 추가 */
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.3rem;
  }

  .hero-details li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .book-list,
  .course-list {
    grid-template-columns: 1fr;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 600;
}

.container h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #1a1a1a;
}
