.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 28px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.chat-icon:hover {
  transform: scale(1.1);
}

.chat-window {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chat-window form {
  display: flex;
  flex-direction: column;
}

.chat-window input,
.chat-window textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.chat-window input:focus,
.chat-window textarea:focus {
  border-color: #007bff;
  outline: none;
}

.chat-window button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.chat-window button:hover {
  background-color: #0056b3;
}

.chat-header {
  margin-bottom: 10px; /* 입력 필드와의 간격 */
  font-size: 14px; /* 폰트 크기 조정 */
  color: #333; /* 텍스트 색상 */
  text-align: center; /* 중앙 정렬 */
  font-weight: 500; /* 폰트 두께 조정 */
}
